.googleBtn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.googleIconWrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}

.googleIcon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}

.btnText {
  float: left;
  margin: 11px 0px 0 60px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}

.googleBtn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.googleBtn:active {
  background: #1669f2;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
